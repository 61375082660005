<template>
  <section class="container-fluid parceiros__container">
    <v-row class="d-flex align-center">
      <div class="d-flex justify-start container">
         <Breadcrumb class="projeto_section_bc-color">
          <template v-slot:breadcrumbLinks>
            <router-link
              tag="p"
              :to="{ name: link.href }"
              v-for="(link, idx) in breadcrumbItems"
              :key="idx"
            >
              {{ link.text }}
            </router-link>
          </template>
          </Breadcrumb>
      </div>
      
    </v-row>
    <v-row class="d-flex justify-center">
    <img width="30%" src="../assets/projeto_card_cabf.png"></v-row>
    <v-row class="d-flex justify-center">
      <v-col cols="11" md="6" class="d-flex justify-center align-center">
        <div class="border__image d-flex justify-center align-center">
          <Card
            title_alignment="left"
            borderColor="#E06919"
            titleColor="#E06919"
            title="Quem é a Comissão de Atingidos pela Barragem de Fundão?"
          >
            <template v-slot:conteudo>
              <p>
                Em 5 de novembro de 2015, o maior desastre–crime socioambiental
                do Brasil devastou comunidades inteiras, matou 19 pessoas
                imediatamente e deixou um rastro de perdas e danos nas vidas de
                milhares de famílias. Menos de 10 dias após o desastre–crime, no
                dia 14 de novembro de 2015, as comunidades atingidas escolheram,
                em Assembleia Geral, os primeiros representantes da Comissão de
                Atingidos pela Barragem de Fundão de Mariana, a CABF. Já no dia
                28 do mesmo mês, em outra Assembleia Geral convocada pela
                Prefeitura Municipal de Mariana (PMM), essa comissão foi
                referendada e ampliada. Além da PMM, a Assembleia contou com a
                participação do Ministério Público de Minas Gerais (MPMG), da
                Câmara Municipal de Mariana, da Ordem dos Advogados do Brasil
                (OAB de Mariana); das Defensorias Públicas da União e do Estado
                de Minas Gerais; da Arquidiocese de Mariana, dentre outros.
                Dessa forma, foi consolidado um grupo de representantes eleitos
                pelas comunidades Bento Rodrigues, Camargos, Ponte do Gama,
                Paracatu de Baixo, Paracatu de Cima, Borba, Pedras e Campinas.
              </p>
              <p>
                A CABF tem como objetivo a luta pelos direitos de todas as
                pessoas atingidas de Mariana. Objetivo esse que, ao longo dos
                anos de atuação, se ampliou, uma vez que a luta por direitos
                nesse território gera precedentes importantes para a luta de
                todas as pessoas atingidas pela mineração, dentro e fora do
                estado de Minas Gerais — seja por rompimentos de barragens, seja
                pelo terrorismo causado pelas atividades minerárias e pelos
                riscos de novos rompimentos. Em parceria com a <router-link tag="a" style="text-decoration: none" class="pink--text" :to="{ name: 'sobre_assessoria_tecnica' }">Assessoria
                Técnica Independente</router-link> (Cáritas MG) e com o <router-link tag="a" style="text-decoration: none" class="pink--text" :to="{ name: 'projeto_sirene' }">Jornal A Sirene</router-link>, a
                CABF leva informações confiáveis, garantindo a participação
                informada das comunidades atingidas de Mariana no processo de
                reparação.
              </p>
              <p>
                Ao longo dos anos de atuação, a CABF se tornou referência na
                luta pela reparação integral dos crimes cometidos pela
                mineração. Esta referência se consolidou entre as pessoas
                atingidas de Mariana, mas também se espalhou para outros
                territórios atingidos, como, por exemplo, toda a bacia do rio
                Doce, litoral do Espírito Santo (também atingidos pelo
                rompimento da barragem da Samarco, Vale e BHP), Brumadinho e
                bacia do rio Paraopeba (atingidos pelo rompimento da barragem da
                Vale, em 25 janeiro de 2019), comunidade de Antônio Pereira em
                Ouro Preto (atingidos pelo risco de rompimento da barragem da
                Vale), entre muitos outros. A CABF serviu de exemplo para a
                formação de Comissões de Atingidos em diversos territórios,
                exemplo de que a luta organizada é possível. Com isso, gerou
                esperança e possibilitou o empoderamento de pessoas, famílias e
                comunidades inteiras que hoje também lutam por seus direitos.
                Exemplo pioneiro de auto-organização, a CABF construiu uma rede
                de parcerias em torno da luta pela reparação integral, agregando
                articulações com atores estratégicos como o MPMG, a Arquidiocese
                de Mariana, Universidades Públicas, Rede de Saúde da Prefeitura
                de Mariana, movimentos sociais entre diversos outros agentes da
                sociedade civil.
              </p>
            </template>
          </Card>
        </div>
      </v-col>
      <v-col cols="12" md="5" class="d-flex justify-center justify-md-center">
        <div class="d-flex flex-column justify-center align-center">
           <img height="35%" width="100%" src="../assets/projeto_cabf_01.png" alt="" />
          <p style="font-size: 0.5em">
            Integrantes da CABF, 2022.
          </p>
          </div>       
       
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center mt-5 mb-5 flex-md-row-reverse">
      <v-col cols="11" md="6" class="d-flex justify-center align-center">
        <div class="border__image d-flex justify-center align-center">
          <Card borderColor="#E06919" >
            <template v-slot:conteudo>
              <p>
                <p>Entre as principais conquistas alcançadas a partir do árduo trabalho da CABF estão:<p>
                <ul>
                    <li>
                    O direito à Assessora Técnica Independente (Cáritas MG); 
                    </li>
                    <li>
                    A reformulação do Cadastro e a aplicação do mesmo pela ATI; 
                    </li>
                    <li>
                    A participação na mesa de negociações nas audiências judiciais que discutem a reparação; 
                    </li>
                    <li>
                    A manutenção do processo de reparação na esfera da justiça estadual; 
                    </li>
                    <li>
                    A manutenção do bloqueio judicial de 300 milhões da Samarco para fins de reparação das perdas e danos; 
                    </li>
                    <li>
                    A não permuta das terras atingidas pelo rejeito e a definição de que as pessoas atingidas, junto ao poder público, é que vão decidir como serão destinadas esses territórios; 
                    </li>
                    <li>
                    Os reassentamentos coletivos, familiares e reconstruções que atendam a todas as comunidades atingidas e a construção de diretrizes de restituição do direito à moradia; 
                    </li>
                    <li>
                    A elaboração da matriz de danos com a participação das pessoas atingidas (a CABF continua na luta para que a matriz seja aplicada em sua integralidade); 
                    </li>
                    <li>
                    A conquista de auxílio emergencial financeiro para muitas famílias atingidas que tiveram perda de renda;
                    </li>
                    <li>
                    A conquista da moradia provisória (aluguel); 
                    </li>
                    <li>
                    A conquista ao reassentamento para novos núcleos familiares formados após o rompimento da barragem e que possuem direito à moradia; 
                    </li>
                    <li>
                    A conquista da Assessoria Jurídica da Cáritas para acompanhamento na Fase de Negociação Extrajudicial das indenizações; 
                    </li>
                    <li>
                    A conquista da rede Conviver, voltada para a saúde mental da população atingida muito adoecida em decorrência do crime;
                    </li>
                    <li>
                    Destinação do recurso, fruto de doações, para o Jornal A Sirene, para a Feira Noturna e para o time de futebol de Bento Rodrigues.
                    </li>
                    <li>
                    A conquista das escolas próprias para as crianças e adolescentes de Bento Rodrigues e Paracatu de Baixo na área central de Mariana. Uma forma de reduzir os danos causados pela descriminação e o preconceito sofrido.
                    </li>
                </ul>

                Estas e outras conquistas foram alcançadas a partir da luta da CABF em conjunto com as comunidades atingidas e organizações parceiras. A CABF é grata a todas as pessoas e organizações que tanto nos apoiaram, seja aqueles que foram solidários às pessoas atingidas logo depois do rompimento da barragem seja os que permaneceram ao nosso lado nesses vários anos de luta. 

                <strong>Seguiremos firmes em nosso propósito!</strong>
              </p>
            </template>
          </Card>
        </div>
      </v-col>
      <v-col cols="12" md="5" class="d-flex justify-center justify-md-start">
        <div class="d-flex flex-column justify-center align-center">
           <img height="35%" width="100%" src="../assets/projeto_cabf_02.png" alt="" />
          <p style="font-size: 0.5em">
            Comissão dos atingidos. Foto: Jornal A Sirene, 2017.
          </p>
          </div>   
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center align-center">
        <v-col class="d-flex justify-center align-center">
          <div class="inside__card creators__box">
            <p
              class="text--primary mb-0 pl-5 pr-5 text-align-center"
            >
              Foi elaborado e assinado pelos membros da Comissão dos Atingidos pela Barragem do Fundão de Mariana, com apoio de Ellen Barros<sup>1</sup>.
            </p>
          </div>
        </v-col>
      </v-row>


      <v-row class="d-flex justify-center align-center">
        <v-col class="d-flex justify-center align-center">
          <div class="inside__card" style="max-width: 90%;">
            <p
              class="text--primary mb-0 pl-5 pr-5 text-align-center"
            >
              <sup>1</sup> Relações Públicas, Jornalista e Mestra em Comunicação (PUC Minas). Comunicadora na ATI Mariana desde 2018.
            </p>
          </div>
        </v-col>
      </v-row>




    
  </section>
</template>

<script>
import Card from "@/components/Card";
import Breadcrumb from '@/layout/Breadcrumb'

export default {
  components: { Card, Breadcrumb },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Parceiros /",
          href: "projeto_parceiros",
        },
        {
          text: "A Sirene /",
          href: "projeto_sirene",
        },
        {
          text: "CABF",
          href: "projeto_cabf",
        },
      ],
    };
  },
};
</script>

<style scoped>

.parceiros__container {
  background-image: url("../assets/projeto_parceiros_background_01.png");
  background-size: cover;
  background-position: center center;
  height: 100%;
}

li {
  margin-bottom: 1em;
}

section {
  background-color: #f9f7ed;
  padding-top: 2em;
  padding-bottom: 2em;
  margin: 0;
}

.creators__box {
  max-width: 40%;
}
.creators__box p {
    text-align: center;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
  margin-bottom: 0;
}

@media (max-width: 600px) {
  .border__image {
    width: 100%;
  }

  img {
    height: 100%;
    width: 100%;
  }
}

.border__image p {
  font-size: 14px;
}
.border__image h3 {
  color: #e06919;
}
</style>
